// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-descargas-js": () => import("./../../../src/pages/descargas.js" /* webpackChunkName: "component---src-pages-descargas-js" */),
  "component---src-pages-donaciones-js": () => import("./../../../src/pages/donaciones.js" /* webpackChunkName: "component---src-pages-donaciones-js" */),
  "component---src-pages-ejercicios-js": () => import("./../../../src/pages/ejercicios.js" /* webpackChunkName: "component---src-pages-ejercicios-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sobre-nosotros-js": () => import("./../../../src/pages/sobre-nosotros.js" /* webpackChunkName: "component---src-pages-sobre-nosotros-js" */)
}

